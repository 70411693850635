.l-site-nav {
  $nav-breakpoint: 'lg';
  $offcanvas-width: 18.75rem;
  $offcanvas-top: 0; // could also be navbar height
  $offcanvas-bg-color: $primary;
  $offcanvas-y: 2.5rem;
  $offcanvas-nav-link-padding-x: 1.25rem;
  $offcanvas-nav-link-padding-y: 1.25rem;
  $offcanvas-active-bg: $primary;

  position: relative;
  z-index: $zindex-offcanvas;
  background-color: transparent;
  padding-top: 1.875rem;
  padding-bottom: 1.875rem;

  transition: $transition-base;

  @include media-breakpoint-up(lg) {
    padding-top: 3.75rem;
    padding-bottom: 3.75rem;
  }

  .navbar-brand img {
    height: 3.875rem;
    max-width: 15.625rem;
    @include media-breakpoint-down($nav-breakpoint) {
      width: 50vw;
      max-width: 12rem;
      height: auto;
    }
  }

  .offcanvas {
    border-left: 0;

    @include media-breakpoint-up($nav-breakpoint) {
      position: static;
      visibility: visible !important;
      background: none;
      border: 0;
      transform: none;
      width: auto;
      margin-left: auto;
    }
    @include media-breakpoint-down($nav-breakpoint) {
      width: $offcanvas-width;
      background-color: $offcanvas-bg-color;
      padding-top: $offcanvas-y;
      padding-bottom: $offcanvas-y;
    }

    .nav-item {
      .nav-link {
        line-height: 1;
        display: flex;
        align-items: center;
        @include media-breakpoint-down($nav-breakpoint) {
          padding: $offcanvas-nav-link-padding-y $offcanvas-nav-link-padding-x;
          font-weight: $font-weight-semibold;
          @include font-size(18px);
        }
        @include media-breakpoint-between(lg,xl) {
          padding-left: $nav-link-padding-x * 0.5;
          padding-right: $nav-link-padding-x * 0.5;
        }
        @include media-breakpoint-down($nav-breakpoint) {
          color: $navbar-dark-color;
        }
      }
      @include media-breakpoint-up(lg) {
        &:first-child > .nav-link {
          padding-left: $nav-link-padding-x * 2;
        }
        &:last-child > .nav-link {
          padding-right: $nav-link-padding-x * 2;
        }
      }

    }

    .navbar-nav {
      @include media-breakpoint-up($nav-breakpoint) {
        background-color: $blue-200;
        border-radius: $border-radius-pill;
        display: flex;
        align-items: center;
      }
    }

    .btn-close {
      top: 1.875rem;
      right: 1.25rem;
      @include media-breakpoint-up($nav-breakpoint) {
        display: none;
      }
    }

    .badge {
      min-width: 1.5rem;
      height: 1.5rem;
      border-radius: 1.5rem * 0.5;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;

      > span:not(.visually-hidden) {
        top: -1px;
      }
    }

    @include media-breakpoint-down($nav-breakpoint) {
      &,
      &.hiding,
      &.showing {
        .btn-close {
          visibility: hidden;
        }
      }
      &.show:not(.hiding):not(.showing) {
        .btn-close {
          visibility: visible;
        }
      }
    }

  }

  .badge {
    position: relative;
    top: 2px;
  }

  &.state-scrolled-up,
  &.state-scrolled-down {
    background-color: $white;
    padding-top: 1.875rem;
    padding-bottom: 1.875rem;
  }

}
