@each $name, $value in $theme-colors {
  .fill-#{$name} {
    fill: $value;
  }
  .stroke-#{$name} {
    stroke: $value;
    fill: none;
  }
}

.fill-white {
  fill: $white;
}

.fill-black {
  fill: $black;
}

.stroke-white {
  stroke: $white;
  fill: none;
}

.stroke-black {
  stroke: $black;
  fill: none;
}
