#cookies-notice {
  $width: 26rem; // best not larger than $modal-md - 2px

  &.is-loading {
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
  }

  .cookie-message {
    box-shadow: 0 2.2px 1.7px rgba(0, 0, 0, 0.022),
    0 5.3px 4px rgba(0, 0, 0, 0.028),
    0 10px 7.5px rgba(0, 0, 0, 0.03),
    0 17.9px 13.4px rgba(0, 0, 0, 0.033),
    0 33.4px 25.1px rgba(0, 0, 0, 0.039),
    0 80px 60px rgba(0, 0, 0, 0.06);
    width: $width;
    max-width: calc(100% - #{$grid-gutter-width});
  }

  .btn-success{
    @include media-breakpoint-down(sm){
      flex-grow: 10;
    }
  }

  background: rgba($modal-backdrop-bg, $modal-backdrop-opacity);
  z-index: $z-index-cookies;
  transition: 0.5s ease-out;
}

#cookie-customizer-modal{
  table {
    font-size: 0.75rem;
  }

  .cookie-group {
    border-top: 1px solid rgba(0, 0, 0, 0.15);
  }

  .modal-footer .btn,
  .buttons-wrapper .btn {
    @include media-breakpoint-only(xs) {
      width: 100%;
    }
  }

  .dropdown-details .dropdown-toggle {
    &::after {
      transform: translateY(2px);
    }

    &[aria-expanded="true"]::after {
      transform: translateY(2px) scale(-1);
    }
  }

  &.show {
    ~ .modal-backdrop {
      opacity: $modal-backdrop-opacity;
    }
  }

}

#cookies-notice + #cookie-customizer-modal.show {
  ~ .modal-backdrop {
    opacity: 0;
  }
}

