.chart-legend {

  $gap: 10px;
  display: flex;
  flex-wrap: wrap;
  margin: (-($gap * 2)) (-$gap) (-$gap) 0;

  .chart-legend-item {
    display: flex;

    &:not(:first-child) {
      margin-top: $gap * 2;
    }

    .badge {
      $size: 20px;
      display: block;
      width: $size;
      height: $size;
      border-radius: $size * 0.5;
      flex: 0 0 $size;
      margin: 0 ($size * 0.5) 0 0;
    }

    .label {
      @include font-size(14px);
      color: $primary;
    }

  }

  > div {
    flex: 0 0 calc(100%);
    margin-top: $gap * 2;
    @include media-breakpoint-up(lg) {
      flex: 0 0 calc(50%);
      padding: 0 $gap;
    }
  }

}
