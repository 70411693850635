div[class*='widget-'] {

	padding-top: 4rem;
	padding-bottom: 4rem;
  scroll-margin-top: 124px;

	&.widget-timeline {
		.timeline-grid {
			.timeline-card {
				> div {
					.description {
						@include font-size(24px);
					}
					position: relative;
				}
        + .timeline-card {
          &:before {
            content: '';
            display: block;
            width: 2px;
            height: 1.25rem;
            position: absolute;
            top: -1.25rem;
            left: 50%;
            transform: translateX(-50%);
            background-color: $primary;
          }
        }
			}
		}
		> .container-fluid {
			@include media-breakpoint-down(lg) {
				padding-right: $grid-gutter-width;
				padding-left: $grid-gutter-width;
			}
		}
	}

	&.widget-four-images {

		> .container-fluid {
			@include media-breakpoint-down(lg) {
				padding-right: $grid-gutter-width;
				padding-left: $grid-gutter-width;
			}
		}
	}

  &.widget-team {
    .card {
      img {
        + img {
          display: none;
        }
      }
      &:hover {
        img {
          display: none;
          + img {
            display: block;
          }
        }
      }
    }
  }

	&.bg-blue-200 + .bg-blue-200,
	&.bg-white + .bg-white {
		padding-top: 0;
	}

	&:first-child {
		&:not(.bg-blue-200) {
      padding-top: 0;
    }
	}

}
