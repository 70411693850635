.anim-search-glass {
  z-index: 1;
  background-color: $primary;

  > div {
    transition: $transition-fade;
  }

  [class*='bg-pattern-'] {
    clip-path: circle(200px at var(--mouse-pos-x) var(--mouse-pos-y));
    z-index: 2;

    &.bg-pattern-dark {
      opacity: 0.5;
    }

    + div {
      position: relative;
      z-index: 100;
    }
  }
}
