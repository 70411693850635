textarea.form-control {
  min-height: 10rem;
}

.form-check {
  label {
    a {
      color: $form-check-label-color;
    }
  }
}
