* {
  @include font-smoothing(on);

  &::selection {
    color: $body-bg;
    background: lighten($primary, 10%);
  }
}

html {
  position: relative;
  overflow-y: scroll;
  overflow-x: hidden;
  min-height: 100%;
}

body {
  word-break: break-word;
}

main {
  flex-shrink: 0;
  padding-top: 6.875rem;
  @include media-breakpoint-up(lg) {
    padding-top: 11.25rem;
  }
}

.container-fluid {
  max-width: $container-fluid-max-width;
  padding-left: $grid-gutter-width * 0.5;
  padding-right: $grid-gutter-width * 0.5;
  @include media-breakpoint-up(md) {
    padding-left: $grid-gutter-width;
    padding-right: $grid-gutter-width;
  }
  @include media-breakpoint-up(xl) {
    padding-left: $grid-gutter-width * 2;
    padding-right: $grid-gutter-width * 2;
  }
}

.bg-none {
  background: none !important;
}

pre {
  overflow: visible;
}

.bg-pattern-hover,
.bg-pattern-dark,
.bg-pattern-darker,
.bg-pattern-light,
.bg-pattern-lighter {
  background-image: url('../../img/patterns/pattern_dark.svg');
  background-repeat: repeat;
  background-size: 800px;
}

.bg-pattern-darker {
  background-image: url('../../img/patterns/pattern_darker.svg');
}

.bg-pattern-lighter {
  background-image: url('../../img/patterns/pattern_lighter.svg');
}

.bg-pattern-light {
  background-image: url('../../img/patterns/pattern_light.svg');
}

.ls-2 {
  letter-spacing: 0.125rem;
}

.parallelogram {
  clip-path: polygon(20% 0%, 100% 0%, 80% 100%, 0% 100%);
}

.cms-text {
  p,
  li {
    color: $blue-300;
    font-weight: $font-weight-normal;
    line-height: $line-height-lg;
  }
}

.skew {
  transform: skewX(-20deg);
  .skew-inner {
    transform: skewX(20deg);
  }
}

.section-pt-lg {
  padding-top: 4rem;
}

.section-pb-lg {
  padding-bottom: 4rem;
}

.bg-triangle {
  width: 400px;
  height: 400px;
  img {
    position: absolute;
    top: 0;
    left: 0;
  }
}


.list-social {
  $size: 2rem;
  gap: 10px;
  a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: $size;
    height: $size;
    border-radius: 50%;
    background-color: $primary;
    text-decoration: none;
  }
}

.mix-blend-mode-multiply {
  mix-blend-mode: multiply;
}

.page-top-triangle {
  @include media-breakpoint-up(lg) {
    width: 400px;
    height: 400px;
    svg {
      position: relative;
      bottom: -1px;
      left: -1px;
    }
  }
}

.top-140 {
  top: 8.75rem;
}

.anim-triangle {
  > div {
    opacity: 0;
  }
}

.page-navigation {
  p {
    flex: 0 0 auto;
  }
}

