.cms-text {
  word-break: break-word;

  p a:not(.btn) {
    text-decoration: underline;
    &,
    strong {
      font-weight: 400;
    }
  }

  strong {
    font-weight: 600;
    color: $blue-400;
  }

  ol,
  ul {
    margin-top: $grid-gutter-width * 0.5;
    margin-bottom: $grid-gutter-width * 0.5;
  }

  p,
  ul,
  ol,
  a,
  table {
    margin-top: 0;
    margin-bottom: 1.25rem;
    &:last-child {
      margin-bottom: 0;
    }
  }
}


