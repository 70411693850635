.filter-item {
  .btn-filter {
    .badge {
      min-width: 1.5rem;
      height: 1.5rem;
      border-radius: 0.75rem;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $primary;
    }
    &:focus,
    &:active,
    &:visited {
      box-shadow: none;
    }
  }
  li {
    &.show-all {
      background-color: $blue-200;
      .btn-filter-reset {
        color: $primary;
        padding: 15px 5px;
        border: 0;
        background-color: transparent;
        width: 100%;
        &:hover,
        &:focus,
        &:active {
          background-color: $primary;
          color: $white;
        }
      }
    }
  }
}
