.l-site-footer {

  a:not(.btn) {
    &:hover,
    &:focus,
    &.active {
      color: $navbar-light-hover-color;
    }
  }

  address {
    span + span {
        margin-top: 0.75rem;
    }
  }

  .btn-primary {
    flex: 1 1 auto;
    @include media-breakpoint-up(sm) {
      flex: 0 0 auto;
    }
  }

  .navbar-brand {
    img {
      width: 3.75rem;
    }
  }

  .link-social {
    height: 55px;
    width: 55px;
  }

  .btn-group {
    @include media-breakpoint-up(lg) {
      a {
        flex: 0 0 auto;
      }
    }
  }

  .meta {

    $dot: 0.25rem;

    p,
    ul > li > a {
      padding: 0.625rem 1rem;
    }

    ul {
      li {
        display: flex;
        align-items: center;
        a {
          display: block;
          @include media-breakpoint-down(md) {
            color: $blue-300;
          }
        }
        &:not(:last-child) {
          &:after {
            content: '';
            display: inline-block;
            width: $dot;
            height: $dot;
            border-radius: $dot * 0.5;
            background-color: $navbar-light-color;
            @include media-breakpoint-down(md) {
              background-color: $blue-300;
            }
          }
        }
      }
      @include media-breakpoint-up(md) {
        margin-right: -1rem;
      }
    }

    p {
      @include media-breakpoint-up(md) {
        margin-left: -1rem;
      }
    }

  }
}
