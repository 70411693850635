main#page-home {

  height: 100vh;

  @include media-breakpoint-down(lg) {
    &:before {
      content: '';
      display: block;
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-image: url('../../img/patterns/pattern_dark.svg');
      background-repeat: repeat;
      background-size: 250px;
      opacity: 0.3;
    }
  }

  @include media-breakpoint-up(lg) {
    .triangle {
      &:before {
        content: "";
        display: block;
        background-color: white;
        position: absolute;
        top: 0;
        right: 100%;
        bottom: 0;
        width: 100vw;
        height: 100%;
      }
    }
  }

}
