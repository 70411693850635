.btn-primary,
.btn-success,
.btn-danger,
.btn-warning {
    padding: $btn-padding-y - 0.1875rem $btn-padding-x $btn-padding-y $btn-padding-x;
    svg {
        &[class*='stroke-'] {
            fill: transparent;
            stroke-width: 2;
        }
        &:first-child {
            margin-right: 1rem;
        }
        &:last-child {
            margin-left: 1rem;
        }
    }
  &.btn-sm {
    padding: $btn-padding-y-sm $btn-padding-x-sm $btn-padding-y-sm;
    font-size: $btn-font-size-sm;
  }
}

.btn-anim {

  $iconSize: 0.75rem;
  $gutter: 1rem;

  position: relative;
  display: inline-flex;
  overflow: hidden;
  align-items: center;
  padding: $btn-padding-y - 0.1875rem calc($btn-padding-x + $iconSize + $gutter) $btn-padding-y $btn-padding-x;
  transition: $transition-base;

  svg {
    transition: $transition-base;
    &:first-child,
    &:last-child {
      position: absolute;
      right: $btn-padding-x;
      top: 50%;
      transform: translate(0, -50%);
    }
    &:first-child {
      right: auto;
      left: 0;
      transform: translate(-100%, -50%);
    }
    &[class*='stroke-'] {
      fill: transparent;
      stroke-width: 2;
    }
  }

  &:hover,
  &:focus {
    padding-right: $btn-padding-x;
    padding-left: calc($btn-padding-x + $iconSize + $gutter);
    svg {
      &:first-child {
        left: calc($btn-padding-x + $iconSize);
      }
      &:last-child {
        right: calc(($btn-padding-x + $iconSize + $gutter) * -1);
      }
    }
  }
}

.btn-close,
.hamburger {
    $radius: 3.125rem;
    background-color: $blue-200;
    padding: 0;
    width: $radius;
    height: $radius;
    border-radius: $radius * 0.5;
    transition: $transition-base;
    opacity: 1;
    .hamburger-box {
      top: 1px;
    }
    .hamburger-inner,
    .hamburger-inner::before,
    .hamburger-inner::after {
      background: $dark;
      transition: $transition-base;
    }
    &:hover,
    &:focus {
      background-color: $primary;
      .hamburger-inner,
      .hamburger-inner::before,
      .hamburger-inner::after {
        background: $white;
      }
    }
  }

.btn-sm {
  padding: $btn-padding-y-sm $btn-padding-x-sm;
}
