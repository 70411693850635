@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";

// Custom
$container-fluid-max-width: 125rem;
$z-index-cookies: $zindex-modal - 1;

// Color system
$white: #fff;

$blue-900: #001C2D;
$blue-400: #5b7f97;
$blue-300: #80A1B7;
$blue-200: #E0ECF4;

$green-900: #28B69E;

$primary: $blue-800;
$success: $green-900;
$dark: $primary;
$light: $white;

$theme-colors: (
		"primary": $primary,
		"secondary": $secondary,
		"success": $success,
		"info": $info,
		"warning": $warning,
		"danger": $danger,
		"blue-900": $blue-900,
		"blue-300": $blue-300,
		"blue-200": $blue-200,
		"light": $light,
		"dark": $dark
);

// Options
$enable-negative-margins: true;
$enable-gradients: false;
$enable-validation-icons: false;

// Spacing
$spacer: 0.625rem;
$spacers: (
		0: 0,
		1: $spacer,
		2: $spacer * 2,
		3: $spacer * 3,
		4: $spacer * 4,
		5: $spacer * 5,
		6: $spacer * 6,
		8: $spacer * 8,
		9: $spacer * 9,
		10: $spacer * 10,
		11: $spacer * 11,
		12: $spacer * 12,
		13: $spacer * 13,
		14: $spacer * 14
);

// Body
$body-color: $blue-300;

// Grid columns
$grid-gutter-width: 2.5rem;
$container-padding-x: $grid-gutter-width * 0.625;
$gutters: $spacers;

// Components
$box-shadow: none;
$box-shadow-sm: none;
$box-shadow-lg: none;
$box-shadow-inset: none;

// Typography
$font-family-sans-serif: "Mundial", "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

$font-size-base: 1rem;

$font-weight-lighter: 100;
$font-weight-normal: 400;
$font-weight-semibold: 600;

$font-weight-base: $font-weight-semibold;

$line-height-base: 1.2;

$h1-font-size: $font-size-base * 3.125;
$h2-font-size: $font-size-base * 2.5;
$h3-font-size: $font-size-base * 1.875;
$h4-font-size: $font-size-base * 1.25;
$h5-font-size: $font-size-base;
$h6-font-size: $font-size-base * 0.625;

$font-sizes: (
		1: $h1-font-size,
		2: $h2-font-size,
		3: $h3-font-size,
		4: $h4-font-size,
		5: $h5-font-size,
		6: $h6-font-size,
		10: 0.625rem,
		12: 0.75rem,
		14: 0.875rem,
		15: 0.9375rem,
		16: 1.0625rem,
		17: 1rem,
		18: 1.125rem,
);

$headings-margin-bottom: $spacer * 4;
$headings-font-family: $font-family-sans-serif;
$headings-font-weight: $font-weight-semibold;
$headings-line-height: 1.1;

$display-font-sizes: (
		1: 3.75rem,
);

$display-font-family: $headings-font-family;
$display-font-weight: $font-weight-lighter;

// Buttons + Forms
$input-btn-padding-y: 1.125rem;
$input-btn-padding-x: 1.625rem;
$input-btn-padding-y-sm: 0.75rem;
$input-btn-padding-x-sm: 0.9375rem;
$input-btn-font-size: 1rem;
$input-btn-line-height: 1.2;

// Buttons
$btn-padding-y: $input-btn-padding-y;
$btn-padding-x: $input-btn-padding-x;
$btn-padding-y-sm: $input-btn-padding-y-sm;
$btn-padding-x-sm: $input-btn-padding-x-sm;
$btn-font-family: $input-btn-font-family;
$btn-font-size: $input-btn-font-size;
$btn-line-height: $input-btn-line-height;
$btn-font-weight: $font-weight-semibold;
$btn-box-shadow: none;
$btn-focus-box-shadow: none;
$btn-active-box-shadow: none;
$btn-link-color: $white;

$btn-padding-y-sm:            0.75rem;
$btn-padding-x-sm:            0.9375rem;
$btn-font-size-sm:            $input-btn-font-size-sm;

// scss-docs-start dropdown-variables
$dropdown-min-width:                8rem;
$dropdown-padding-x:                0;
$dropdown-padding-y:                0.75rem;
$dropdown-spacer:                   .125rem;
$dropdown-font-size:                $font-size-base;
$dropdown-color:                    $gray-500;
$dropdown-bg:                       $white;
$dropdown-border-color:             $primary;
$dropdown-border-radius:            $border-radius;
$dropdown-border-width:             $border-width;
$dropdown-inner-border-radius:      subtract($dropdown-border-radius, $dropdown-border-width);
$dropdown-divider-bg:               $dropdown-border-color;
$dropdown-divider-margin-y:         $spacer * .5;
$dropdown-box-shadow:               $box-shadow;

$dropdown-link-color:               $gray-500;
$dropdown-link-hover-color:         $primary;
$dropdown-link-hover-bg:            $dropdown-bg;

$dropdown-link-active-color:        $primary;
$dropdown-link-active-bg:           $dropdown-bg;

$dropdown-link-disabled-color:      $gray-200;

$dropdown-item-padding-y:           0.625rem;
$dropdown-item-padding-x:           0.625rem;

$dropdown-header-color:             $gray-600;
$dropdown-header-padding-x:         $dropdown-item-padding-x;
$dropdown-header-padding-y:         $dropdown-padding-y;

// Forms
$form-label-color:                     $blue-300;

$input-padding-y:                       1.125rem;
$input-padding-x:                       1.25rem;
$input-font-size:                       1rem;
$input-font-weight:                     $font-weight-normal;
$input-line-height:                     1.2;

$input-bg:                              $white;
$input-disabled-color:                  null;
$input-disabled-bg:                     $blue-300;
$input-disabled-border-color:           null;

$input-color:                           $primary;
$input-border-color:                    $primary;
$input-border-width:                    $input-btn-border-width;
$input-box-shadow:                      $box-shadow-inset;

$input-border-radius:                   0;
$input-border-radius-sm:                0;
$input-border-radius-lg:                0;

$input-focus-bg:                        $input-bg;
$input-focus-border-color:              $primary;
$input-focus-color:                     $primary;
$input-focus-box-shadow:                none;

$input-placeholder-color:               $blue-300;
$input-plaintext-color:                 $primary;

$input-height-border:                   $input-border-width * 2;

$form-floating-padding-x:         $input-padding-x;
$form-floating-padding-y:         $input-padding-y;
$form-floating-input-padding-t:   1.625rem;
$form-floating-input-padding-b:   .625rem;
$form-floating-label-opacity:     1;
$form-floating-label-transform:   scale(.85) translateY(-.5rem) translateX(.15rem);
$form-floating-transition:        opacity .1s ease-in-out, transform .1s ease-in-out;

$form-check-label-color:                  $primary;
$form-check-input-width:                  1.5em;
$form-check-padding-start:                $form-check-input-width + .75em;
$form-check-margin-bottom:                .125rem;

$form-check-input-bg:                     $input-bg;
$form-check-input-border:                 $input-border-width solid $input-border-color;
$form-check-input-border-radius:          $input-border-radius;
$form-check-radio-border-radius:          50%;
$form-check-input-focus-border:           $input-focus-border-color;
$form-check-input-focus-box-shadow:       none;

$form-check-input-checked-color:          $component-active-color;
$form-check-input-checked-bg-color:       $primary;
$form-check-input-checked-border-color:   $form-check-input-checked-bg-color;
$form-check-input-checked-bg-image:       url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path fill='none' stroke='#{$form-check-input-checked-color}' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/></svg>");
$form-check-radio-checked-bg-image:       url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='2' fill='#{$form-check-input-checked-color}'/></svg>");

$form-check-input-indeterminate-color:          $component-active-color;
$form-check-input-indeterminate-bg-color:       $component-active-bg;
$form-check-input-indeterminate-border-color:   $form-check-input-indeterminate-bg-color;
$form-check-input-indeterminate-bg-image:       url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path fill='none' stroke='#{$form-check-input-indeterminate-color}' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/></svg>");

$form-check-input-disabled-opacity:        .5;
$form-check-label-disabled-opacity:        $form-check-input-disabled-opacity;
$form-check-btn-check-disabled-opacity:    $btn-disabled-opacity;

$form-check-inline-margin-end:    1rem;

// Navs
$nav-link-padding-y: 1.1875rem;
$nav-link-padding-x: 1.125rem;
$nav-link-color: $primary;
$nav-link-hover-color: $success;

// Navbar
$navbar-padding-y: $nav-link-padding-y;
$navbar-padding-x: $nav-link-padding-x;

$navbar-nav-link-padding-x: $nav-link-padding-x;

$navbar-dark-color: $light;
$navbar-dark-hover-color: $success;
$navbar-dark-active-color: $success;

$navbar-light-color: $dark;
$navbar-light-hover-color: $success;
$navbar-light-active-color: $success;

// Badges
$badge-font-size: 0.875rem;
$badge-font-weight: $font-weight-semibold;
$badge-padding-y: 0;
$badge-padding-x: 0;

// Offcanvas
$offcanvas-horizontal-width: 400px;
$offcanvas-border-color: $blue-200;
$offcanvas-bg-color: $primary;
$offcanvas-color: $modal-content-color;
$offcanvas-box-shadow: none;
$offcanvas-backdrop-bg: $blue-200;
$offcanvas-backdrop-opacity: 0.95;
